import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Configuration } from "./Config";
import { NavLink, Link } from "react-router-dom";
import { BsFillCaretDownFill, BsFillCaretRightFill } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";

function BookTickets({ ticket, eventDetails, onSubmitPressed, details }) {
  const [formFields, setFormFields] = useState([]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");

  const [detailsExpand, setDetailsExpand] = useState(false);

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    if (event.target.name === "name") {
      data[index][event.target.name] = event.target.value;
    } else {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        data[index][event.target.name] = event.target.value.substring(0, 10);
      }
    }

    setFormFields(data);
  };

  function isEmpty(str) {
    return !str.trim().length;
  }

  /**
   * on submit button pressed
   * @param {} e
   * @returns
   */
  const submit = (e) => {
    if (formFields.length === 0) {
      addFields();
      setError(true);
      setErrorMessage("Add minimum 1 participant");
      e.preventDefault();
      return;
    }
    const result = formFields.filter(
      (obj) => isEmpty(obj.name) || isEmpty(obj.mobile)
    );
    if (result.length) {
      setError(true);
      setErrorMessage("Name and Mobile number could't be empty field");
      e.preventDefault();
      return;
    }
    const result1 = formFields.filter(
      (obj) => obj.mobile.length < 10 || obj.mobile.length > 10
    );
    if (result1.length) {
      setError(true);
      setErrorMessage("Enter valid mobile number");
      e.preventDefault();
      return;
    }

    e.preventDefault();
    setError(false);

    localStorage.setItem("current-user", JSON.stringify(formFields[0]));

    // call user login api
    callLoginApi(formFields[0].mobile);
  };

  /**
   * call login api
   * @param {*} mobileNumber
   */
  async function callLoginApi(mobileNumber) {
    const body = {
      mobile: mobileNumber,
      is_from_event_booking_site: 1,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    fetch(`${Configuration.BASE_URL}users/sign-in`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          // call verify otp
          callVerifyOtp(mobileNumber, data.otp);
        } else if (data.status === "ERROR" && data.code === 902) {
          //call register api
          callRegisterApi(mobileNumber);
        }
      });
  }

  /**
   * call registration api if login failed
   * @param {*} mobileNumber
   */
  async function callRegisterApi(mobileNumber) {
    const body = {
      mobile: mobileNumber,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    fetch(`${Configuration.BASE_URL}users/register`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          // call verify otp
          callLoginApi(mobileNumber);
        }
      });
  }

  /**
   * call verify otp after success full login
   * @param {*} mobileNumber
   * @param {*} otp
   */
  async function callVerifyOtp(mobileNumber, otp) {
    const body = {
      mobile: mobileNumber,
      otp: otp,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    fetch(`${Configuration.BASE_URL}users/verify-otp`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS" && data.data) {
          // store auth token
          localStorage.setItem("auth-token", `Bearer ${data.data.token}`);
          callBookTicketApi();
        }
      });
  }

  /**
   * call book tickets api
   */
  async function callBookTicketApi() {
    setError(false);
    const body = {
      tickets: [{ ticket_id: ticket.id, quantity: formFields.length }],
      attendee_list: formFields,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("auth-token"),
      },
      body: JSON.stringify(body),
    };
    fetch(
      `${Configuration.BASE_URL}event/${eventDetails.id}/book-ticket`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          console.log("ticketBookingResponse", data);
          if (data && data.data) {
            onSubmitPressed(data.data);
          }
        } else if (data.status === "ERROR") {
          setError(true);
          setErrorMessage(data.message);
        }
      });
  }

  const addFields = () => {
    if (formFields.length <= 4) {
      let object = {
        name: "",
        mobile: "",
      };

      setFormFields([...formFields, object]);
    }
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  return (
    <>
      <div className="booktickets container-fluid fontloader">
        <div>
          <NavLink
            to={"/"}
            className="p-2 flex flex-row items-center w-[70px] rounded"
            style={{
              textDecoration: "none",
              color: "white",
              marginTop: "-30px",
              backgroundColor: "#FB6304",
            }}
          >
            <BsChevronLeft /> Back
          </NavLink>
        </div>

        <div
          style={{ textDecoration: "none", width: "50%", marginTop: "-7px" }}
          className="titleBookTicket fontloader"
        >
          BOOK TICKETS
        </div>

        <div></div>

        <div
          className="container book-details"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div className="flex-1">
            <h5 style={{ fontWeight: "45px" }}>{ticket.name}</h5>
            <div className="flex flex-row items-center justify-between">
              <p>₹{ticket.price}</p>

              <div
                className="fontloader flex flex-row items-center justify-center gap-1 py-2"
                style={{
                  color: "#ee7e1a",
                  fontSize: "20px",
                }}
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  onClick={addFields}
                  src={require("./images/plus.png")}
                  alt="plus"
                />
                <b>Add</b>
                {formFields.length > 0 && (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    onClick={removeFields}
                    src={require("./images/minus.png")}
                    alt="minus"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="container book-details" style={{ margin: "-10px" }}>
            <details onClick={() => setDetailsExpand((o) => !o)}>
              <summary style={{ display: "flex" }}>
                View Details
                {detailsExpand ? (
                  <BsFillCaretDownFill style={{ margin: "5px" }} />
                ) : (
                  <BsFillCaretRightFill style={{ margin: "5px" }} />
                )}
              </summary>
              {/* <p style={{float: "left", fontSize: "11px"}}>Details :-</p> */}

              <div style={{ fontSize: "11px" }}>
                <div style={{ paddingTop: "10px" }}>
                  {ticket.description.map((det) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "4px",
                      }}
                    >
                      <div>
                        <img
                          className="book-details-img"
                          src={require("./images/basic_pass.png")}
                          alt="viewdetails"
                        />
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        <span>{det}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>

        <br />
        <div
          className=" container alert alert-danger"
          role="alert"
          style={{ fontSize: "11px" }}
        >
          Ticket will be credited in first user’s Knocksense account !
        </div>
        {formFields.length > 0 && (
          <Container className="book-details">
            <div onSubmit={submit}>
              {formFields.map((form, index) => {
                return (
                  <div className="input-group" key={index}>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.name}
                    />
                    &nbsp;&nbsp;
                    <input
                      type="phoneno"
                      className="form-control"
                      name="mobile"
                      placeholder="Contact Number"
                      id="exampleInputphoneno"
                      onChange={(event) => handleFormChange(event, index)}
                      value={form.mobile}
                    />
                    &nbsp;&nbsp;
                  </div>
                );
              })}

              {error && (
                <div
                  style={{
                    color: "#ee1a1a",
                    marginTop: "20px",
                  }}
                >
                  <p>{errorMsg}</p>
                </div>
              )}
            </div>
          </Container>
        )}

        <Link
          onClick={submit}
          style={{
            textDecoration: "none",
            backgroundColor: "#FB6304",
            width: "50%",
            marginTop: "5px",
          }}
          className="bookticket_submit fontloader"
        >
          SUBMIT DETAILS
        </Link>
      </div>
    </>
  );
}

export default BookTickets;
