import React, { useEffect, useState } from "react";
import { Configuration } from "../../../Config";
import moment from "moment";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box, Paper, Stack, Typography } from "@mui/material";

import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

function isEmpty(str) {
  return !str || str.trim().length === 0;
}

const FeatureEventsSlider = ({ currentLocation, searchQuery }) => {
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const isDesktopApp = useMediaQuery(
    json2mq({
      minWidth: 769,
    })
  );

  useEffect(() => {
    getFeatureEvents(currentLocation, searchQuery);
  }, [currentLocation, searchQuery]);

  async function getFeatureEvents(locationId, searchQuery) {
    const url = isEmpty(searchQuery)
      ? `${Configuration.BASE_URL}event/web-events?is_featured=true&location_id=${locationId}`
      : `${Configuration.BASE_URL}event/web-events?is_featured=true&location_id=${locationId}&search=${searchQuery}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          if (data.data.event_list && data.data.event_list.length > 0) {
            setEvents(data.data.event_list);
          } else {
            setEvents([]);
          }
        }
      });
  }

  return (
    <div>
      {events.length > 0 ? (
        <>
          <Typography
            sx={{ margin: "1vh 6vw", fontWeight: "800", color: "#A57D24" }}
          >
            Featured Events
          </Typography>
          <Stack>
            <div
              style={{
                padding: "0 6vw",
              }}
            >
              <Carousel
                onClickItem={(index, item) => {
                  const eventItem = events[index];
                  navigate(
                    eventItem.slug
                      ? `/event/${eventItem.slug}`
                      : `/${eventItem.id}`
                  );
                }}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                showIndicators={events.length > 1}
                autoPlay
                infiniteLoop
              >
                {events.map((event) => (
                  <Paper variant="outlined" sx={{ borderRadius: "10px 10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        borderRadius: "10px 10px",
                        height: isDesktopApp ? "40vh" : "20vh",
                        backgroundImage: `url(${event.feature_image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: `cover`,
                      }}
                    >
                      <Stack
                        sx={{
                          width: "100vw",
                          background: " rgba(0,0,0,0.5)",
                          borderRadius: "10px 10px",
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "2vw",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#fff",
                            textAlign: "left",
                          }}
                        >
                          {moment(event.date).format("DD MMM YYYY")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "800",
                            color: "#fff",
                            textAlign: "left",
                          }}
                        >
                          {event.name}
                        </Typography>
                      </Stack>
                    </Box>
                  </Paper>
                ))}
              </Carousel>
            </div>
          </Stack>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FeatureEventsSlider;
