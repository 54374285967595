import { createTheme } from '@mui/material/styles';


const DefaultTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#0b98af',
      },
      secondary: {
        main: '#ed7833',
      },
    },
    typography: {
      fontFamily: '"Nunito Sans"',
    },
  });

export default DefaultTheme;
