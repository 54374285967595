import "./App.css";
import Homepage from "./pages/Home/Homepage";
import BookTickets from "./BookTickets";
import Checkout from "./Checkout";
import Thankyoupage from "./Thankyoupage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllEvents from "./RelatedEvent/AllEvents";
import EventNotAvailable from "../src/RelatedEvent/EventNotAvailable";
import { useEffect, useState } from "react";
import { Configuration } from "./Config";

const App = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getAllEvents();
  }, []);

  async function getAllEvents() {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${Configuration.BASE_URL}event/web-events`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          if (data.data.event_list && data.data.event_list.length > 0) {
            setEvents(data.data.event_list);
          }
        }
      });
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<AllEvents />} />
          <Route
            exact
            path="/eventnotavailable"
            element={<EventNotAvailable />}
          />
          <Route exact path="/:eventId" element={<Homepage />} />
          <Route exact path="/bookTickets" element={<BookTickets />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route
            exact
            path="/thankyoupage/:eventId"
            element={<Thankyoupage />}
          />
            {/* <Route exact path="/event/:slug" element={<Homepage />} /> */}
          {events.map((eventObj) => (
            <Route
              exact
              path={`/event/${eventObj.slug}`}
              element={<Homepage customEventId={eventObj.id} />}
            />
          ))}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
