import moment from 'moment';
import _ from 'lodash';

export const getEventDates = (startDate, endDate) => {
    if (_.isEmpty(endDate) || _.isEqual(startDate.substring(0,9), endDate.substring(0,9)) ) {
      return moment(startDate).format('DD MMM YYYY');
    }
    return `${moment(startDate).format('DD MMM YYYY')} to ${moment(
      endDate,
    ).format('DD MMM YYYY')} `;
  };