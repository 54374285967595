import React from "react";
import {
  BsFillCalendarCheckFill,
  BsFillStopwatchFill,
} from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Configuration } from "./Config";

function Checkout({
  eventDetails,
  ticketDetails,
  proceedPaytmPayment,
  selectedTicket,
}) {
  const { Attendee_data } = ticketDetails;

  async function callTicketPaymentApi() {
    const body = {
      tickets: [
        { ticket_id: selectedTicket.id, quantity: Attendee_data.length },
      ],
      total: ticketDetails.total,
      is_web_booking: true,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("auth-token"),
      },
      body: JSON.stringify(body),
    };
    fetch(
      `${Configuration.BASE_URL}event/${eventDetails.id}/ticket-payment`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          console.log("ticketPaymentResponse", data);
          if (data && data.data) {
            proceedPaytmPayment(data.data);
          }
        }
      });
  }

  return (
    <>
      <div className="text-center booktickets container-fluid fontloader">
        <h3>CHECKOUT </h3>

        <div className="container-fluid booktickets">
          <img
            className="img-fluid image-container"
            src={eventDetails.thumbnail_image}
            alt="thumbnail"
          />
          <Card.Body>
            <div className="col">
              <h3>{eventDetails.name}</h3>
            </div>
            <hr style={{ height: "10px", color: "white" }}></hr>

            <div className="row fontloader">
              <div className="col-md">
                <div className="flex flex-row items-center gap-2">
                  <BsFillCalendarCheckFill />{" "}
                  {moment(selectedTicket.eventDate).format("ddd, DD MMM YYYY")}
                </div>
              </div>

              <div className="col-md">
                <div className="flex flex-row items-center gap-2">
                  <BsFillStopwatchFill />
                  {moment(eventDetails.start_time).format("hh:mm A")} Onwards
                </div>
              </div>
            </div>

            <div>
              <p className="flex flex-row items-center gap-2 py-2 text-start ">
                {eventDetails.location}
              </p>
            </div>
          </Card.Body>
        </div>

        <div className="fontloader checkout-user-details">
          <div className="row">
            <div className="col-4">
              <b>User Details </b>
            </div>
            <div className="col-5">
              <></>
            </div>

            <div className="col-3 text-end">
              <div>
                <b>Price</b>
              </div>
            </div>
          </div>

          {Attendee_data.map((attendee) => (
            <div className="row">
              <hr className="hrnew1"></hr>

              <div className="fontloader ">
                <div className="row text-start">
                  <div
                    className="col-4 text-start"
                    style={{ marginLeft: "10px" }}
                  >
                    {attendee.name}
                  </div>
                  <div className="col">
                    <></>
                  </div>
                  <div className="col-4 text-end">
                    <div style={{ marginLeft: "-5px" }}>₹ {attendee.price}</div>
                  </div>
                </div>
              </div>

              <div className="fontloader">
                <div className="row">
                  <div className="col-4" style={{ marginLeft: "4px" }}>
                    {attendee.mobile}
                  </div>

                  <div className="col">
                    <></>
                  </div>

                  <div className="col-4 text-end">
                    <div style={{ color: "#ee7e1a", marginLeft: "-30px" }}>
                      {attendee.plan}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="fontloader checkout-user-details">
          <div className="row">
            <div className="col-3" style={{ marginLeft: "-8px" }}>
              <b>Total </b>
            </div>

            <div className="col">
              <></>
            </div>
            <div className="col">
              <></>
            </div>
            <div className="col-4 text-end">
              <div style={{ marginLeft: "5px" }}>
                {" "}
                <b>₹ {ticketDetails.total} </b>
              </div>
            </div>
          </div>
        </div>

        <NavLink
          onClick={() => {
            callTicketPaymentApi();
          }}
          exact
          type="button"
          className="checkout_submit fontloader btn"
          style={{ backgroundColor: "#FB6304" }}
        >
          {" "}
          <b>PROCEED TO PAY</b>{" "}
        </NavLink>
      </div>
    </>
  );
}

export default Checkout;
