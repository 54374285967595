import React, {  } from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const GalleryImageComponent = ({ images }) => {
  // const [currentImage, setCurrentImage] = useState(0);
  // const [isViewerOpen, setIsViewerOpen] = useState(false);

  // const { open } = useGallery();

  // const openImageViewer = useCallback((index) => {
  //   setCurrentImage(index);
  //   setIsViewerOpen(true);
  // }, []);

  // const closeImageViewer = () => {
  //   setCurrentImage(0);
  //   setIsViewerOpen(false);
  // };

  return (
    <div>
      <div
        style={{
          width: "95%",
          overflow: "auto",
          display: "flex",
          marginLeft: "2%",
          marginRight: "2%",
        }}
      >
        <Gallery id="my-gallery" options={{ arrowKeys: true }} >
          {images.map((image, index) => (
            <Item original={image.url} thumbnail={image.url} width="1024" height="1024">
              {({ ref, open }) => (
                <Image
                  onClick={open}
                  ref={ref}
                  style={{ height: "100px", marginRight: "2%", cursor: 'pointer'  }}
                  src={image.url}
                  thumbnail
                />
              )}
            </Item>
          ))}
        </Gallery>
      </div>

      {/* {isViewerOpen && (
        <ImageViewer
          src={images.map((image) => image.url)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )} */}
    </div>
  );
};

export default GalleryImageComponent;

GalleryImageComponent.propTypes = {
  images: PropTypes.array.isRequired,
};

GalleryImageComponent.defaultProps = {
  images: [],
};
