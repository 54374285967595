import React, { useEffect, useState } from "react";
import { Configuration } from "../Config";
import "./RelatedEvent.css";
import NoEvents from "./NoEvents";
import { getEventDates } from "../utils/DateUtils";

function CallEvents({ onEventItemPressed, currentLocation, searchQuery }) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // getRelatedEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllEvents(currentLocation, searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, searchQuery]);

  // const getEventsArray = () => {
  //   return events.filter((item) =>
  //     item.name.toLowerCase().includes(searchQuery.toLowerCase())
  //   );
  // };

  function isEmpty(str) {
    return !str || str.trim().length === 0;
  }

  async function getAllEvents(locationId, searchQuery) {
    setLoading(true);
    const url = isEmpty(searchQuery)
      ? `${Configuration.BASE_URL}event/web-events?location_id=${locationId}`
      : `${Configuration.BASE_URL}event/web-events?location_id=${locationId}&search=${searchQuery}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "SUCCESS") {
          if (data.data.event_list && data.data.event_list.length > 0) {
            setEvents(data.data.event_list);
          } else {
            setEvents([]);
          }
        }
      });
  }

  const Event = ({ eventData, onItemClick }) => {
    return (
      <div
        className="card event-item"
        onClick={() => onItemClick(eventData)}
      >
        <div>
          <img
            src={eventData.cover_image}
            className="event-image"
            alt="Responsive_image"
          />
          <div className="event-name">{eventData.name}</div>
          <p className="event-start-date">
            {getEventDates(eventData.date, eventData.end_date)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {events.length > 0 && (
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 lg:grid-cols-8  xl:grid-cols-9 2xl:grid-cols-12   md:grid-cols-6 mx-4">
          {events.map((event) => (
            <Event eventData={event} onItemClick={onEventItemPressed} />
          ))}
        </div>
      )}

      {!loading && events.length === 0 && <NoEvents />}
    </div>
  );
}

export default CallEvents;
