import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { BsLightningFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <Navbar className="backgound-header" variant="dark">
        <Container>
          <Link to="/">
            <img
              alt="knocksenselogo"
              src="/whitelogo-transprent.png"
              width="160"
              height="64"
              to="/"
            />
          </Link>
          <div className="flex flex-row bg-white rounded-md items-center justify-center px-2 py-1">
            <BsLightningFill /> <b>BOOKING SITE</b>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
