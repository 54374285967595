import React, { useEffect, useState } from "react";
import CallEvents from "./CallEvents";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Configuration } from "../Config";
import { Stack, Typography } from "@mui/material";
import FeatureEventsSlider from "../screens/events-list/feature-events/index";

const AllEvents = () => {
  const navigate = useNavigate();

  const [currentLocationId, setCurrentLocationId] = useState(0);
  const [eventSearchQuery, setSearchQuery] = useState("");

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    //set loader\
    fetch(`${Configuration.BASE_URL}users/locality`)
      .then((res) => res.json())
      .then((response) => {
        if (!response.error) {
          setLocations(response.data);
        }
      });
  }

  const onChangeLocation = (e) => {
    setCurrentLocationId(e.target.value);
  };

  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <InputGroup
              size="md"
              className="mb-3"
              style={{ border: "solid", borderRadius: "8px" }}
            >
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Events Search"
                value={eventSearchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col size="md" className="selectlocation">
            <select
              onChange={onChangeLocation}
              className="form-select"
              aria-label="Default select example"
            >
              <option value="0" selected={!currentLocationId}>
                All Locations
              </option>
              {locations.map((item, index) => (
                <option
                  value={item.id}
                  selected={item.id === currentLocationId}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>

      <Stack>
        <FeatureEventsSlider
          currentLocation={currentLocationId}
          searchQuery={eventSearchQuery}
        />

        <Typography
          sx={{ margin: "1vh 6vw", fontWeight: "800", color: "#A57D24" }}
        >
          All Events
        </Typography>

        <CallEvents
          currentLocation={currentLocationId}
          searchQuery={eventSearchQuery}
          onEventItemPressed={(eventData) => {
            navigate(
              eventData.slug ? `/event/${eventData.slug}` : `/${eventData.id}`
            );
            window.location.reload(false);
          }}
        />
      </Stack>

      <div style={{ marginTop: "31%" }}>
        <center>
          <a
            href="http://onelink.to/knocksense"
            type="button"
            className="btn .text-center fontloader gradientcolor"
          >
            Download the Knocksense App Here!
          </a>
        </center>
      </div>
    </div>
  );
};

export default AllEvents;
