import React from 'react'
import { Link } from "react-router-dom";
import Header from "../Header";

const EventNotAvailable = () => {
    return (
        <div>
             <Header />
             <div className='container'>
                <h6 style={{ marginTop: "35px", color: "#808080" }}> <center>
                    <b> The Event is no longer available, <br/> 
                    Please go to the home page for more events!</b>
                </center></h6>
            </div>
            <center style={{padding: "20px"}}><Link to="/">
                <button type="button" class="btn btn-success">Homepage</button>
            </Link> </center>


        </div>
    )
}

export default EventNotAvailable;